<template>
  <div class="member-age-statistics">
    <ChartTitle
      title="會員年齡分佈"
      :showOptions="false"
      :shopSelector="true"
      @shopChange="onShopChange"
    />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { dateRangeConfig } from '@/config/dashboard'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import { useChart } from '@/use/dashboard'
import { generateMemberAgeConfig, getAgeData, getAgeDataByShop } from '../../common'
import { useOrg } from '@/use/useOrg'
import { useShop } from '@/use/shop'
import { getOrgShopChartData } from '@/utils/dashboard'
import { get, values, cloneDeep, forEach } from 'lodash'

export default defineComponent({
  name: 'MemberAgeStatistics',
  components: { ChartTitle },
  emits: ['getTime'],
  setup (props, { emit }) {
    const { chartRef, updateChart } = useChart(generateMemberAgeConfig())
    const { shopId } = useShop()
    const { orgId } = useOrg()

    const baseChartOptions = ref({})
    const shopsData = ref({})
    const displayChartOptions = ref({})

    const refreshChartData = () => {
      const newOptions = cloneDeep(baseChartOptions.value)
      newOptions.series[0].name = '組織'
      newOptions.series[0].type = 'bar'
      newOptions.series.push(...values(shopsData.value))
      displayChartOptions.value = newOptions

      // newOptions.chart.type = 'bar'
      newOptions.legend = {
        show: true,
        position: 'top',
      }
      newOptions.plotOptions = {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      }

      newOptions.yaxis[0].title.text = '人數'
      newOptions.xaxis.title.text = '年齡'

      newOptions.chart.toolbar.tools.zoom = true
      newOptions.xaxis.tickPlacement = 'on'
      updateChart(newOptions)
    }

    const onShopChange = async (newShops) => {
      const newShopsData = {}

      for (const shop of newShops) {
        if (get(shopsData.value, shop.id)) {
          newShopsData[shop.id] = get(shopsData.value, shop.id)
        } else {
          newShopsData[shop.id] = {
            name: shop.name,
            data: await getAgeDataByShop({
              orgId: orgId.value,
              shopId: shop.id,
            }),
          }
        }
      }

      shopsData.value = newShopsData

      refreshChartData()
    }

    onMounted(async () => {
      const { newOptions, lastUpdatedAt } = await getAgeData({
        orgId: orgId.value,
      })
      baseChartOptions.value = newOptions
      // updateChart(newOptions)
      refreshChartData()
      emit('getTime', lastUpdatedAt)

      console.log(chartRef.value)
    })

    return {
      chartRef,
      dateRangeConfig,
      onShopChange,
      shopsData,
      baseChartOptions,
      displayChartOptions,
    }
  },
})
</script>

<style lang="postcss" scoped>
.member-age-statistics {
  @apply flex flex-col gap-5;
}
</style>
